@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /**
* @license
*
* Font Family: Archivo
* Designed by: Omnibus-Type
* URL: https://www.fontshare.com/fonts/archivo
* © 2024 Indian Type Foundry
*
* Archivo Thin 
* Archivo ThinItalic 
* Archivo ExtraLight 
* Archivo ExtraLightItalic 
* Archivo Light 
* Archivo LightItalic 
* Archivo Regular 
* Archivo Italic 
* Archivo Medium 
* Archivo MediumItalic 
* Archivo SemiBold 
* Archivo SemiBoldItalic 
* Archivo Bold 
* Archivo BoldItalic 
* Archivo ExtraBold 
* Archivo ExtraBoldItalic 
* Archivo Black 
* Archivo BlackItalic 
* Archivo Variable (Variable font)
* Archivo VariableItalic (Variable font)

*
*/
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-Thin.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-Thin.woff") format("woff"),
      url("fonts/Archivo/Archivo-Thin.ttf") format("truetype");
    font-weight: 100;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-ThinItalic.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-ThinItalic.woff") format("woff"),
      url("fonts/Archivo/Archivo-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-display: swap;
    font-style: italic;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-ExtraLight.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-ExtraLight.woff") format("woff"),
      url("fonts/Archivo/Archivo-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-ExtraLightItalic.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-ExtraLightItalic.woff") format("woff"),
      url("fonts/Archivo/Archivo-ExtraLightItalic.ttf") format("truetype");
    font-weight: 200;
    font-display: swap;
    font-style: italic;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-Light.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-Light.woff") format("woff"),
      url("fonts/Archivo/Archivo-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-LightItalic.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-LightItalic.woff") format("woff"),
      url("fonts/Archivo/Archivo-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
    font-style: italic;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-Regular.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-Regular.woff") format("woff"),
      url("fonts/Archivo/Archivo-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-Italic.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-Italic.woff") format("woff"),
      url("fonts/Archivo/Archivo-Italic.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: italic;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-Medium.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-Medium.woff") format("woff"),
      url("fonts/Archivo/Archivo-Medium.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-MediumItalic.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-MediumItalic.woff") format("woff"),
      url("fonts/Archivo/Archivo-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: italic;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-SemiBold.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-SemiBold.woff") format("woff"),
      url("fonts/Archivo/Archivo-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-SemiBoldItalic.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-SemiBoldItalic.woff") format("woff"),
      url("fonts/Archivo/Archivo-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-display: swap;
    font-style: italic;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-Bold.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-Bold.woff") format("woff"),
      url("fonts/Archivo/Archivo-Bold.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-BoldItalic.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-BoldItalic.woff") format("woff"),
      url("fonts/Archivo/Archivo-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: italic;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-ExtraBold.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-ExtraBold.woff") format("woff"),
      url("fonts/Archivo/Archivo-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-ExtraBoldItalic.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-ExtraBoldItalic.woff") format("woff"),
      url("fonts/Archivo/Archivo-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-display: swap;
    font-style: italic;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-Black.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-Black.woff") format("woff"),
      url("fonts/Archivo/Archivo-Black.ttf") format("truetype");
    font-weight: 900;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-BlackItalic.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-BlackItalic.woff") format("woff"),
      url("fonts/Archivo/Archivo-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-display: swap;
    font-style: italic;
  }
  /**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 600.0;
*
* available axes:
'wght' (range from 100.0 to 900.0
*/
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-Variable.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-Variable.woff") format("woff"),
      url("fonts/Archivo/Archivo-Variable.ttf") format("truetype");
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
  }
  /**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 600.0;
*
* available axes:
'wght' (range from 100.0 to 900.0
*/
  @font-face {
    font-family: "Archivo";
    src: url("fonts/Archivo/Archivo-VariableItalic.woff2") format("woff2"),
      url("fonts/Archivo/Archivo-VariableItalic.woff") format("woff"),
      url("fonts/Archivo/Archivo-VariableItalic.ttf") format("truetype");
    font-weight: 100 900;
    font-display: swap;
    font-style: italic;
  }

  /**
* @license
*
* Font Family: Clash Display
* Designed by: Indian Type Foundry
* URL: https://www.fontshare.com/fonts/clash-display
* © 2024 Indian Type Foundry
*
* Clash Display Extralight 
* Clash Display Light 
* Clash Display Regular 
* Clash Display Medium 
* Clash Display Semibold 
* Clash Display Bold 
* Clash Display Variable (Variable font)

*
*/
  @font-face {
    font-family: "ClashDisplay";
    src: url("fonts/ClashDisplay/ClashDisplay-Extralight.woff2") format("woff2"),
      url("fonts/ClashDisplay/ClashDisplay-Extralight.woff") format("woff"),
      url("fonts/ClashDisplay/ClashDisplay-Extralight.ttf") format("truetype");
    font-weight: 200;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "ClashDisplay";
    src: url("fonts/ClashDisplay/ClashDisplay-Light.woff2") format("woff2"),
      url("fonts/ClashDisplay/ClashDisplay-Light.woff") format("woff"),
      url("fonts/ClashDisplay/ClashDisplay-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "ClashDisplay";
    src: url("fonts/ClashDisplay/ClashDisplay-Regular.woff2") format("woff2"),
      url("fonts/ClashDisplay/ClashDisplay-Regular.woff") format("woff"),
      url("fonts/ClashDisplay/ClashDisplay-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "ClashDisplay";
    src: url("fonts/ClashDisplay/ClashDisplay-Medium.woff2") format("woff2"),
      url("fonts/ClashDisplay/ClashDisplay-Medium.woff") format("woff"),
      url("fonts/ClashDisplay/ClashDisplay-Medium.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "ClashDisplay";
    src: url("fonts/ClashDisplay/ClashDisplay-Semibold.woff2") format("woff2"),
      url("fonts/ClashDisplay/ClashDisplay-Semibold.woff") format("woff"),
      url("fonts/ClashDisplay/ClashDisplay-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "ClashDisplay";
    src: url("fonts/ClashDisplay/ClashDisplay-Bold.woff2") format("woff2"),
      url("fonts/ClashDisplay/ClashDisplay-Bold.woff") format("woff"),
      url("fonts/ClashDisplay/ClashDisplay-Bold.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }
  /**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 700.0;
*
* available axes:
'wght' (range from 200.0 to 700.0
*/
  @font-face {
    font-family: "ClashDisplay";
    src: url("fonts/ClashDisplay/ClashDisplay-Variable.woff2") format("woff2"),
      url("fonts/ClashDisplay/ClashDisplay-Variable.woff") format("woff"),
      url("fonts/ClashDisplay/ClashDisplay-Variable.ttf") format("truetype");
    font-weight: 200 700;
    font-display: swap;
    font-style: normal;
  }
}
